<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("nav-main.privacy")}}</v-card-title>

        <v-card-subtitle>
            {{$t("g.only-in-german")}}
        </v-card-subtitle>

        <loading-circle v-if="loading"></loading-circle>
        <v-card-text v-if="!loading" v-html="privacy" class="content-from-textarea"></v-card-text>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                privacy: "",
                loading: false,
            }
        },
        async beforeCreate() {
            this.loading = true;
            let response = await this.func.ax("/php/getter.php", {
                func: "get_privacy",
            });
            this.privacy = response.privacy;
            this.loading = false;
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-privacy')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>